import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-fieldset.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import '@brightspace-ui/core/components/tabs/tabs.js';

import './tabs/custom-terms/custom-terms.js';
import './tabs/manage-activity-instructions/manage-activity-instructions.js';
import './tabs/manage-budget-approvals/manage-budget-approvals.js';
import './tabs/manage-career-explorer/manage-career-explorer.js';
import './tabs/manage-custom-attributes/manage-custom-attributes.js';
import './tabs/manage-custom-content/manage-custom-content.js';
import './tabs/manage-general/manage-general.js';
import './tabs/manage-provider-finance/manage-provider-finance.js';
import './tabs/manage-users/manage-users.js';
import '../../components/visibility-manager/visibility-manager.js';
import '../../../admin/components/audit/audit-table/audit-table.js';
import '../../../admin/components/login-manager/login-manager.js';
import '../../components/skill-stream-manager/skill-stream-manager.js';
import '../../../shared/components/general/nova-side-panel/nova-side-panel.js';
import '../../../shared/components/general/nova-side-panel-item/nova-side-panel-item.js';
import '../../../shared/components/general/nova-side-panel-item-group/nova-side-panel-item-group.js';
import './admin-permissions/edit-permissions/edit-permissions.js';
import './admin-permissions/edit-roles/edit-roles.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { PERMISSIONS } from '../../../../shared/permissions.js';
import Tenant from '../../../../shared/models/tenant/index.js';

/**
 * Shows a list of the courses
 */
export default class EditTenant extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      params: { type: Object },
      _currentRole: { type: String, attribute: false },
      _providers: { type: Array, attribute: false },
      _tenant: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      inputLabelStyles,
      selectStyles,
      css`
        d2l-input-text {
          padding-bottom: 1.5rem;
        }

        .d2l-input-select {
          margin-bottom: 1.5rem;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    this._currentRole = '';

    this._tenant = this.params?.id ?
      await this.client.fetchTenant(this.params.id) :
      new Tenant({ type: this.params.type });

    this._providers = await this.client.listTenants('provider');
  }

  get tabsByTenantType() {
    return {
      employer: [
        { panel: 'general', permissions: [[PERMISSIONS.EMPLOYER_GENERAL_VIEW, PERMISSIONS.EMPLOYER_GENERAL_EDIT]] },
        { panel: 'careerExplorer', permissions: [[PERMISSIONS.EMPLOYER_GENERAL_VIEW]] },
        { panel: 'budgetApprovals', permissions: [[PERMISSIONS.EMPLOYER_GENERAL_VIEW, PERMISSIONS.EMPLOYER_GENERAL_EDIT]] },
        { panel: 'customContent', permissions: [[PERMISSIONS.EMPLOYER_GENERAL_VIEW, PERMISSIONS.EMPLOYER_GENERAL_EDIT]] },
        { panel: 'customAttributes', permissions: [[PERMISSIONS.EMPLOYER_CUSTOMATTRIBUTES_VIEW, PERMISSIONS.EMPLOYER_CUSTOMATTRIBUTES_EDIT]] },
        { panel: 'employeeTerms', permissions: [[PERMISSIONS.EMPLOYER_GENERAL_VIEW, PERMISSIONS.EMPLOYER_GENERAL_EDIT]] },
        { panel: 'faq', permissions: [[PERMISSIONS.EMPLOYER_FAQ_VIEW, PERMISSIONS.EMPLOYER_FAQ_EDIT]] },
        { panel: 'entitlements', permissions: [[PERMISSIONS.EMPLOYER_ENTITLEMENTS_VIEW, PERMISSIONS.EMPLOYER_ENTITLEMENTS_EDIT]] },
        { panel: 'visibility', permissions: [[PERMISSIONS.EMPLOYER_GENERAL_VIEW]] },
        { panel: 'enabledStreams', permissions: [[PERMISSIONS.EMPLOYER_GENERAL_VIEW]] },
        { panel: 'login', permissions: [[PERMISSIONS.EMPLOYER_GENERAL_VIEW]] },
        { panel: 'audit', permissions: [[PERMISSIONS.EMPLOYER_AUDIT_VIEW]] },
      ],
      provider: [
        { panel: 'general', permissions: [[PERMISSIONS.PROVIDER_GENERAL_VIEW, PERMISSIONS.PROVIDER_GENERAL_EDIT]] },
        { panel: 'providerFinance', permissions: [[PERMISSIONS.PROVIDER_FINANCE_VIEW, PERMISSIONS.PROVIDER_FINANCE_EDIT]] },
        { panel: 'customContent', permissions: [[PERMISSIONS.PROVIDER_HOWITWORKS_VIEW, PERMISSIONS.PROVIDER_HOWITWORKS_EDIT]] },
        { panel: 'customAttributes', permissions: [[PERMISSIONS.PROVIDER_CUSTOMATTRIBUTES_VIEW, PERMISSIONS.PROVIDER_CUSTOMATTRIBUTES_EDIT]] },
        { panel: 'faq', permissions: [[PERMISSIONS.PROVIDER_FAQ_VIEW, PERMISSIONS.PROVIDER_FAQ_EDIT]] },
        { panel: 'activityInstructions', permissions: [[PERMISSIONS.PROVIDER_ACTIVITYINSTRUCTIONS_VIEW, PERMISSIONS.PROVIDER_ACTIVITYINSTRUCTIONS_EDIT]] },
        { panel: 'entitlements', permissions: [[PERMISSIONS.PROVIDER_ENTITLEMENTS_VIEW, PERMISSIONS.PROVIDER_ENTITLEMENTS_EDIT]] },
        { panel: 'login', permissions: [[PERMISSIONS.PROVIDER_GENERAL_VIEW]] },
        { panel: 'audit', permissions: [[PERMISSIONS.PROVIDER_AUDIT_VIEW]] },
      ],
      admin: [
        { panel: 'general', permissions: [[PERMISSIONS.PERMISSIONS]] },
        { panel: 'login', permissions: [[PERMISSIONS.PERMISSIONS]] },
        { panel: 'userPermissions', permissions: [[PERMISSIONS.PERMISSIONS]] },
        { panel: 'roles', permissions: [[PERMISSIONS.PERMISSIONS]] },
        { panel: 'audit', permissions: [[PERMISSIONS.PERMISSIONS]] },
      ],
      newTenant: [
        { panel: 'createTenant', permissions: [[PERMISSIONS.PERMISSIONS]] },
      ],
    };
  }

  get templates() {
    return {
      general: {
        title: 'General',
        template: html`<manage-general .tenant=${this._tenant} @update-tenant=${this._updateTenant}></manage-general>`,
        icon: 'tier1:home', // Home or general overview icon
      },
      createTenant: {
        title: 'Create tenant',
        template: html`<h1 class="d2l-heading-1">Create new ${this.params.type} tenant</h1>
        <manage-general .tenant=${this._tenant} @update-tenant=${this._updateTenant} create-mode></manage-general>`,
        icon: 'tier1:home', // Home or general overview icon
      },
      careerExplorer: {
        title: 'Career Explorer',
        template: html`<manage-career-explorer .tenant=${this._tenant} @update-tenant=${this._updateTenant}></manage-career-explorer>`,
        icon: 'tier1:profile-default', // Represents exploring or career profiles
      },
      budgetApprovals: {
        title: this.localize('manage-budget-approvals.title'),
        template: html`<manage-budget-approvals .tenant=${this._tenant} @update-tenant=${this._updateTenant}></manage-budget-approvals>`,
        icon: 'tier1:calculate', // Related to finance or approvals
      },
      providerFinance: {
        title: this.localize('manage-provider-finance.title'),
        template: html`<manage-provider-finance .tenant=${this._tenant} @update-tenant=${this._updateTenant}></manage-provider-finance>`,
        icon: 'tier1:lor', // Finance or money-related tasks
      },
      customContent: {
        title: 'How it works page content',
        template: html`<manage-custom-content itemDisplay="Block" type="homepage" tenantId=${this._tenant.id}></manage-custom-content>`,
        icon: 'tier1:table-of-contents', // Represents content or document management
      },
      customAttributes: {
        title: this.localize('manage-custom-attributes.title'),
        template: html`<manage-custom-attributes
      .userCustomAttributes=${this._tenant.customAttributes}
      .applicationCustomAttributes=${this._tenant.applicationAttributes}
      .tenantId=${this._tenant?.id}>
    </manage-custom-attributes>`,
        icon: 'tier1:tag', // Custom tags or attributes
      },
      employeeTerms: {
        title: 'Employee terms of use',
        template: html`<custom-terms .employerName=${this._tenant.name} .tenantId=${this._tenant.id}></custom-terms>`,
        icon: 'tier1:syllabus', // Legal or terms of use
      },
      faq: {
        title: 'FAQ',
        template: html`<manage-custom-content itemDisplay="FAQ" type="faq" tenantId=${this._tenant.id}></manage-custom-content>`,
        icon: 'tier1:help', // Help or FAQ section
      },
      activityInstructions: {
        title: 'Activity instructions',
        template: html`<manage-activity-instructions .tenant=${this._tenant}></manage-activity-instructions>`,
        icon: 'tier1:glossary', // Instructions or informational
      },
      entitlements: {
        title: 'Entitlements',
        template: html`<manage-users
      type="Entitlement"
      @users-updated=${this._updateEntitlements}
      .users=${this._tenant.entitlements}
      .tenantType=${this._tenant.type}>
    </manage-users>`,
        icon: 'tier1:awards', // Entitlements or user badges
      },
      login: {
        title: 'Login',
        template: html`<login-manager tenant-id="${this._tenant.id}"></login-manager>`,
        icon: 'tier1:profile-default', // Login or profile-related
      },
      visibility: {
        title: 'Visibility',
        template: html`${this._providers ? html`
      <visibility-manager
        .providers=${this._providers}
        .contextTenant=${this._tenant}></visibility-manager>` : html`Loading`}`,
        icon: 'tier1:visibility-show', // Visibility or permissions
      },
      enabledStreams: {
        title: 'Enabled Streams',
        template: html`<skill-stream-manager .contextTenant=${this._tenant}></skill-stream-manager>`,
        icon: 'tier1:rss', // Represents streams or data flow
      },
      audit: {
        title: 'Audit',
        template: html`<audit-table tenant=${this.params.id}></audit-table>`,
        icon: 'tier1:check-circle', // Represents audits or checks
      },
      userPermissions: {
        title: this.localize('edit-permissions.userPermissions'),
        template: html`<edit-permissions></edit-permissions>`,
        icon: 'tier1:lock-locked', // Represents permissions or security
        group: 'permissions',
        groupTitle: 'Permissions',
      },
      roles: {
        title: this.localize('edit-permissions.roles'),
        template: html`<edit-roles></edit-roles>`,
        icon: 'tier1:group', // Represents roles or users
        group: 'permissions',
        groupTitle: 'Permissions',
      },
    };
  }

  _getProcessedTabs(tenantType) {
    const groups = {};
    const type = this.params?.type ? 'newTenant' : tenantType;
    const tabs = this.tabsByTenantType[type];
    const tabsAndGroups = [];

    for (const tabInfo of tabs) {
      const tabData = this.templates[tabInfo.panel];
      const newTabData = {
        ...tabData,
        id: tabInfo.panel,
        type: 'item',
      };

      if (tabData.group) {
        if (!groups[tabData.group]) {
          groups[tabData.group] = {
            id: tabData.group,
            title: tabData.groupTitle,
            tabs: [],
            type: 'group',
          };
          tabsAndGroups.push(groups[tabData.group]);
        }
        groups[tabData.group].tabs.push(newTabData);
      } else {
        tabsAndGroups.push(newTabData);
      }
    }

    return tabsAndGroups;
  }

  render() {
    if (!this._tenant || !this.templates) return nothing;
    const tenantType = this._tenant.type; // Get the current tenant type

    const processedTabs = this._getProcessedTabs(tenantType); // Process tabs based on tenant type

    return html`
      <nova-side-panel logo="${this._tenant.imageUrl}">
        <app-link class="back-link" d2l-link href="/admin#tenants"> ← Back to tenants </app-link>
        ${processedTabs.map(tab => {
    if (tab.type === 'item') {
      // Render individual side panel items
      return html`
            <nova-side-panel-item
              panel="${tab.id}"
              label="${tab.title}"
              icon="${tab.icon}"
              .requiredPermissions="${tab.permissions}">
              ${tab.template}
            </nova-side-panel-item>
          `;
    } else if (tab.type === 'group') {
      // Render grouped side panel items
      return html`
            <nova-side-panel-item-group
              label="${tab.title}"
              icon="tier1:lock-locked">
              ${tab.tabs.map(groupedTab => html`
                <nova-side-panel-item
                  panel="${groupedTab.id}"
                  label="${groupedTab.title}"
                  .requiredPermissions="${groupedTab.permissions}">
                  ${groupedTab.template}
                </nova-side-panel-item>
              `)}
            </nova-side-panel-item-group>
          `;
    }
  })}
      </nova-side-panel>
    `;
  }

  async _updateEntitlements(e) {
    const updatedTenant = await this.client.setEntitlements(this._tenant.id, e.detail.users);
    this._tenant.entitlements = { ...updatedTenant.entitlements };
  }

  _updateTenant(e) {
    const { tenant } = e.detail;
    this._tenant = new Tenant(tenant);
  }
}

window.customElements.define('edit-tenant', EditTenant);
