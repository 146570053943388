import { bodySmallStyles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { css, html, LitElement } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/switch/switch.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-checkbox-spacer.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

export default class ManageUserPermissions extends LocalizeNova(RequesterMixin(SkeletonMixin(nav(LitElement)))) {
  static get properties() {
    return {
      // Add properties here
      user: { type: Object, attribute: false },
      allRoles: { type: Array, attribute: false },
      _roles: { type: Array, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodySmallStyles,
      heading2Styles,
      heading3Styles,
      inputLabelStyles,
      css`
        .checkbox-wrapper {
          padding-bottom: 12px;
        }

        .input-wrapper {
          max-width: 400px;
          padding-bottom: 24px;
        }

        d2l-form {
          padding-bottom: 12px;
        }

        d2l-switch {
          padding-bottom: 12px;;
        }

        d2l-input-checkbox {
          margin-bottom: 0;
        }

        h3.d2l-heading-3 {
          margin-top: 0;
        }
      `];
  }

  constructor() {
    super();
    this.user = null;
    this.allRoles = [];
    this._roles = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    this.skeleton = true;
    if (!this.user) {
      this.user = await this.client.getUserById(this.params?.userId);
      this._roles = this.user?.roles?.map(role => role.roleId) ?? [];
    }
    // TODO: actually get all roles
    this.allRoles = [
      { roleId: '15a6057f-025d-4162-b49e-d97bdb19efdd', name: 'Admin', description: 'Add and update activities in the catalogue with some restrictions' },
      { roleId: '8b744b1a-59d4-4d1f-898e-cb55250c8370', name: 'Catalogue Manager', description: 'Add and update activities in the catalogue' },
      { roleId: '8c84cba9-2a29-4809-b4dc-b99a0ece0cc5', name: 'Corporate Client Manager', description: 'Add and update corporate client settings' },
      { roleId: '878cd2f8-b8fe-4c1f-8850-df5e7154a96c', name: 'Education Partner Manager', description: 'Add and update education partner settings' },
    ];
    this.skeleton = false;
  }

  render() {
    return html`
      <div>
        <h2 class="d2l-heading-2">${this.localize('manage-user-permissions.heading')}</h2>
        <p>${this.localize('manage-user-permissions.pageDescription')}</p>
        <d2l-switch text="${this.localize('manage-user-permissions.userActiveSwitchLabel')}" @change="${this._handleActiveStatusChange}" ?on="${this.user?.active}"></d2l-switch>
        <div class="d2l-skeletize">
          <d2l-form id="form" @d2l-form-submit="${this.validate}" @change="${this._updateSelectedRoles}">
            <div class="input-wrapper">
              <label for="name" class="d2l-input-label d2l-input-label-required">${this.localize('manage-user-permissions.userNameInputLabel')}</label>
              <d2l-input-text
                id="name"
                disabled
                label-hidden
                label="${this.localize('manage-user-permissions.userNameInputLabel')}"
                value="${this.user?.firstName} ${this.user?.lastName}"
                required
                ></d2l-input-text>
            </div>
            <div class="input-wrapper">
              <label for="email" class="d2l-input-label d2l-input-label-required">${this.localize('manage-user-permissions.emailInputLabel')}</label>
              <d2l-input-text
                id="email"
                disabled
                label-hidden
                label="${this.localize('manage-user-permissions.emailInputLabel')}"
                value="${this.user?.email}"
                required
                ></d2l-input-text>
            </div>
            <div class="input-wrapper">
              <label for="title" class="d2l-input-label d2l-input-label-required">${this.localize('manage-user-permissions.titleInputLabel')}</label>
              <d2l-input-text
                id="title"
                disabled
                label-hidden
                label="${this.localize('manage-user-permissions.titleInputLabel')}"
                value="${this.user?.title}"
                required
                ></d2l-input-text>
            </div>
            <h3 class="d2l-heading-3">${this.localize('manage-user-permissions.rolesHeading')}</h3>
            ${repeat(this.allRoles, role => role.roleId, role => html`
              <div class="checkbox-wrapper">
                <d2l-input-checkbox
                id="${role.roleId}"
                ?checked="${this.user?.roles?.some(userRole => userRole.roleId === role.roleId)}"
                >
                  ${role.name}
                </d2l-input-checkbox>
                <d2l-input-checkbox-spacer class="d2l-body-small">
                  ${role.description}
                </d2l-input-checkbox-spacer>
              </div>
            `)}
            <d2l-button @click="${this._submitAndUpdateRoles}" type="submit" primary>${this.localize('manage-user-permissions.updateUserButton')}</d2l-button>
          </d2l-form>
        </div>
      </div>
    `;
  }

  _updateSelectedRoles(e) {
    if (e.target.checked) {
      if (!this._roles.includes(e.target.id)) this._roles.push(e.target.id);
    } else {
      this._roles = this._roles.filter(role => role !== e.target.id);
    }
  }

  _handleActiveStatusChange(e) {
    this.user.active = e.target.on;
  }

  async _submitAndUpdateRoles() {
    // TODO: add toast messages once the body is updated to return a response
    await this.client.bulkUpdateRoleUserAssignments(this.user.guid, this._roles);
  }
}

window.customElements.define('manage-user-permissions', ManageUserPermissions);

